import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react'
import AuthorizationReducer from '../../reducers/authorizationReducer/authorizationReducer'
import {
  IAuthorizationReducerState,
  authorizationActions,
} from '../../reducers/authorizationReducer/authorizationReducer.type'
import {
  initialState,
  IAuthorizationContextState,
} from './AuthorizationContext.type'
import { Auth, Hub, Amplify } from 'aws-amplify'
import { getCookie, removeCookie, setCookie } from 'tiny-cookie'
import { EnumCookieKeys, EnumPageUrl } from '@enums'
import { message } from 'antd'
import { navigate } from 'gatsby'
import { debounce } from 'lodash'
import { navigateTo } from './../../tools/link'

interface IAuthorizationProviderProps {
  children: React.ReactNode
}

Amplify.configure({
  Auth: {
    identityPoolId: 'eu-west-1:d74dc6f9-cf09-4a5f-9c66-98c9a739bf90',
    region: 'eu-west-1',
    userPoolId: 'eu-west-1_Aw2gOoOcz',
    userPoolWebClientId: '38qs4f0aa0qidsi15f01lgih5l',
  },
})

export const AuthorizationContext =
  createContext<IAuthorizationContextState>(initialState)
export const useAuthorizationContext = () => useContext(AuthorizationContext)

export const AuthorizationProvider = ({
  children,
}: IAuthorizationProviderProps) => {
  const [childrenDOM, setChildrenDOM] = useState<React.ReactNode>(null)
  const [state, dispatch]: [
    IAuthorizationReducerState,
    (a: authorizationActions) => any
  ] = useReducer(AuthorizationReducer, initialState) as any

  const signIn = async ({
    username,
    password,
  }): Promise<string | undefined> => {
    try {
      const userRes = await Auth.signIn(username, password)
      if (userRes.challengeName === 'NEW_PASSWORD_REQUIRED') {
        await Auth.completeNewPassword(userRes, password)
      }
      const session = userRes.signInUserSession.accessToken.jwtToken
      setCookie(EnumCookieKeys.AuthToken, session)
      return session
    } catch (error) {}
  }

  const changePassword = async ({
    oldPassword,
    newPassword,
  }): Promise<string | undefined> => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      const res = await Auth.changePassword(user, oldPassword, newPassword)

      return res
    } catch (error) {
      if (error.message === 'Incorrect username or password.')
        return '密码输入错误'
      return error.message
    }
  }

  const signOutCallback = debounce(() => {
    removeCookie(EnumCookieKeys.AuthToken)
    navigateTo(EnumPageUrl.LoginPage)
  }, 300)

  const listenAuthEvent = () => {
    const listener = (data) => {
      switch (data.payload.event) {
        case 'signIn':
          break
        case 'signUp':
          break
        case 'signOut':
          signOutCallback()
          break
        case 'signIn_failure':
          break
        case 'tokenRefresh':
          break
        case 'tokenRefresh_failure':
          signOutCallback()
          break
        case 'configured':
      }
    }

    Hub.listen('auth', listener)
  }

  const verifyLogin = async () => {
    const path =
      typeof window !== 'undefined' &&
      window.location.pathname.replace(/(\/)?/gi, '')
    const cookie = getCookie(EnumCookieKeys.AuthToken)

    try {
      await Auth.currentAuthenticatedUser({
        bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      })

      if (path === EnumPageUrl.LoginPage) {
        message.info('已登陆，正在跳转...')
        setTimeout(() => {
          navigate(EnumPageUrl.OwnStorePage)
        }, 600)
      }

      setChildrenDOM(children)
    } catch (error) {
      if (
        path === EnumPageUrl.LoginPage ||
        path === EnumPageUrl.ResetPassword
      ) {
        setChildrenDOM(children)
      } else {
        if (cookie) message.error('会话已过期，请重新登陆...')
        signOutCallback()
      }
    }
  }

  useEffect(() => {
    verifyLogin()
    listenAuthEvent()
  }, [])

  return (
    <AuthorizationContext.Provider value={{ ...state, signIn, changePassword }}>
      {childrenDOM}
    </AuthorizationContext.Provider>
  )
}
